
export default {//TBA;add logo
    app_info: {
      app_name: "icon-tabs",
      app_id: "55f7e086-b6e6-4119-8e8a-fe2f2395ef13",
      app_title: "Icon Tabs",
      app_icon:
        "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-blog-recipe/Assets/%20Logo.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/10130779-how-to-use-icon-tabs",
      review_url:
        "https://www.wix.com/app-market/add-review/55f7e086-b6e6-4119-8e8a-fe2f2395ef13",
    },
  
    storage: {
      collection_name: "icon-tabs",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "Icon Tabs is a customizable tab bar that enhances navigation by allowing flexible display and content modification. It enables a personalized, user-friendly experience for apps or websites, making organization easier. With simple and adaptable options, Icon Tabs improves interface usability and design.",
      url: "https://help.marketpushapps.com/en/articles/10130779-how-to-use-icon-tabs",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Icon Tabs",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  